import { useCaseService } from "~/src/services/CaseService";
import {
  abortNavigation,
  createError,
  defineNuxtRouteMiddleware,
} from "#imports";

export default defineNuxtRouteMiddleware(async (to) => {
  const { useGetCaseQuery } = useCaseService();
  const caseId = decodeURIComponent(String(to.params.caseId));
  const result = await useGetCaseQuery(caseId);

  if (!result.data.value)
    return abortNavigation(
      createError({
        statusCode: 404,
        message: "Case not found",
      })
    );
});
